import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { PropsWithChildren } from 'react'

type AppLinkLocation = 'rsvp' | 'header'
type StoreName = 'apple app store' | 'google play store'

interface AppLinkProps extends PropsWithChildren {
  location: AppLinkLocation
  className?: string
  store: StoreName
}

const storeLinks = {
  'apple app store': 'https://apps.apple.com/us/app/pie-make-plans-like-magic/id1509667820',
  'google play store': 'https://play.google.com/store/apps/details?id=com.whippedCream.pumpkinPie',
}

export const AppLink = ({ children, className, location, store }: AppLinkProps) => {
  const link = storeLinks[store]
  const handleClick = () => {
    createMixpanelEvent(MixpanelEvent.download_app_tapped, { location, app_store: store })
  }
  return (
    <a className={className} href={link} onClick={handleClick} target="_blank">
      {children}
    </a>
  )
}
