export enum MixpanelEvent {
  appSession = 'session_start',
  viewPlansList = 'plans_feed_viewed',
  viewPlan = 'plan_viewed',
  morePlansForYouLoaded = 'more_plans_for_you_loaded',
  inviteFriendsClicked = 'invite_friends_pressed',
  clickMakePlanButton = 'create_new_plan_pressed',
  viewSignUp = 'sign_up_viewed',
  viewSignUpAddName = 'sign_up_add_name_viewed',
  viewSignUpAddPHoto = 'sign_up_add_photo_viewed',
  sendVerificationCodeClicked = 'send_verification_code_clicked',
  confirmVerificationCodeClicked = 'confirm_verification_code_clicked',
  download_app_tapped = 'download_app_tapped',
  openInBrowserPressed = 'open_in_browser_pressed',
  addToCalendar = 'add_to_calendar_tapped',
}
