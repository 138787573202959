import { gql } from '@apollo/client'

export const DeleteCurrentUser = gql`
  mutation DeleteCurrentUser {
    users {
      deleteMe {
        id
      }
    }
  }
`
