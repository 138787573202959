import { useState } from 'react'
import { DeleteUserModal } from './DeleteUserModal'

export function DeleteButton() {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <>
      <DeleteUserModal show={showModal} onClose={(value: boolean) => setShowModal(value)} />
      <button className="Button-large Button-secondary deleteBttn" onClick={() => setShowModal((prev) => !prev)}>
        Delete Account
      </button>
    </>
  )
}
