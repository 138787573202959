import './GetThePieApp.scss'

import type { JSX } from 'react'
import AppleAppImage from '../../assets/images/pie_app.png'
import AndroidAppImage from '../../assets/images/pie_app_android.png'

import { useDeviceInfo } from '../../hooks/useDeviceInfo'

export function GetThePieApp(): JSX.Element {
  const { isApple } = useDeviceInfo()
  return (
    <div className="GetThePieApp">
      <div className="GetThePieApp__info">
        <h2 className="GetThePieApp__info__title">Get the pie app</h2>
        <p>your social feed of things to do</p>
        <ul>
          <li>find unique plans near you</li>
          <li>see what you're missin' &#129488;</li>
          <li>bring your friends + and make new ones</li>
        </ul>
      </div>
      <div className="GetThePieApp__image">
        <img src={isApple ? AppleAppImage : AndroidAppImage} alt="Pie app screen shot" />
      </div>
    </div>
  )
}
