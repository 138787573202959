import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import './EditProfile.scss'
import {
  CreateFavoriteSpotInput,
  FavoriteSpotsMutations,
  User,
  UserInterestsMutations,
  UsersMutations,
} from '../../../__generated__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { usersUpdateMe } from '../../../graphql/mutations/users-update-me'
import { store } from '../../../store/store'
import { EditPortrait } from './EditPortrait'
import { useSelector } from 'react-redux'
import { useUploadUserPicture } from '../../../hooks/useUploadUserPicture'
import { EditCity } from './EditCity'
import { EditModal } from './EditModal'
import { InfoType } from '../Profile/Profile'
import { Input } from '../../Input/Input'
import { TextArea } from '../../TextArea/TextArea'
import { InterestsPills } from '../../InterestsPills/InterestsPills'
import { interestsFind } from '../../../graphql/queries/interests-find'
import { userInterests } from '../../../graphql/mutations/user-interests-set'
import { user } from '../../../graphql/queries/users-user'
import { EditFavoriteSpots } from './EditFavoriteSpots/EditFavoriteSpots'
import { favoriteSpotsSet } from '../../../graphql/mutations/favorite-spots-set'
import { ConfirmationButtons } from '../../ConfirmationButtons/ConfirmationButtons'
import { DeleteButton } from '../../DeleteUserButton/DeleteUserButton'

export const EditProfile = ({
  infoType,
  userProfile,
  initialInterestsIds,
  setEditProfile,
}: {
  infoType: InfoType
  userProfile: User
  initialInterestsIds: string[]
  setEditProfile: Dispatch<SetStateAction<boolean>>
}) => {
  const [firstName, setFirstName] = useState<string | null>(userProfile.firstName ?? null)
  const [lastName, setLastName] = useState<string | null>(userProfile.lastName ?? null)
  const [bio, setBio] = useState<string | null>(userProfile.bio ?? null)
  const [city, setCity] = useState<string | null>(userProfile.city ?? null)
  const [instagram, setInstagram] = useState<string | null>(userProfile.instagram ?? null)
  const [twitter, setTwitter] = useState<string | null>(userProfile.twitter ?? null)
  const [linkedIn, setLinkedIn] = useState<string | null>(userProfile.linkedIn ?? null)
  const [userPic, setUserPic] = useState('')
  const [picPreview, setPicPreview] = useState(userProfile.imageUrl || '')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [interestsSelected, setInterestsSelected] = useState<string[]>(initialInterestsIds ?? [])
  const extantFavoriteSpotInputs = userProfile.favoriteSpots.map((fs) => {
    return {
      placeId: fs.placeId,
      venue: fs.venue,
      address: fs.address,
      coordinates: fs.coordinates ? { lat: fs.coordinates.lat, lng: fs.coordinates.lng } : undefined,
      generalLocation: fs.generalLocation,
      category: fs.category || null,
    }
  })
  const [favoriteSpots, setFavoriteSpots] = useState<CreateFavoriteSpotInput[]>(extantFavoriteSpotInputs || [])

  const interestsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (infoType === 'interests') {
      interestsRef.current?.scrollIntoView({ behavior: 'auto' })
    }
  }, [])

  const authToken = useSelector((state: any) => {
    return state.authToken
  })

  const [userInterestsMutation] = useMutation<UserInterestsMutations>(userInterests, {
    variables: {
      input: {
        interestIds: [...interestsSelected],
      },
    },
    refetchQueries: [user, interestsFind],
  })

  const [favoriteSpotsMutation] = useMutation<FavoriteSpotsMutations>(favoriteSpotsSet, {
    variables: {
      input: {
        favoriteSpots: [...favoriteSpots],
      },
    },
    refetchQueries: [user],
  })

  const [updateMeMutation] = useMutation<{ users: UsersMutations }>(usersUpdateMe, {
    variables: {
      firstName,
      lastName,
      bio,
      city,
      instagram,
      twitter,
      linkedIn,
    },
  })

  const {
    data,
    loading: interestsLoading,
    error: interestsError,
  } = useQuery(interestsFind, {
    variables: { userId: userProfile.id },
  })

  const interests = data?.interests.find

  const handleCancel = () => {
    if (
      firstName !== userProfile.firstName ||
      lastName !== userProfile.lastName ||
      bio !== userProfile.bio ||
      city !== userProfile.city ||
      instagram !== userProfile.instagram ||
      twitter !== userProfile.twitter ||
      linkedIn !== userProfile.linkedIn
    ) {
      setShowModal(true)
    } else {
      setEditProfile(false)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDiscardChanges = () => {
    setEditProfile(false)
  }

  const onChange = (e: any) => {
    setError('')
    setUserPic(e.target.files[0])
    if (e.target.files[0]) setPicPreview(URL.createObjectURL(e.target.files[0]))
  }

  const handleSaveInfo = async () => {
    if (firstName === null || firstName === '') {
      return
    } else {
      await userInterestsMutation()
      await favoriteSpotsMutation()
      const response = await updateMeMutation()
      useUploadUserPicture({ userPic, authToken, setIsLoading, setError })
      if (response && response.data?.users.updateMe) {
        store.dispatch({ type: 'user/upsert', user: response.data?.users.updateMe })
      }
      setEditProfile(false)
    }
  }

  const isDisabled =
    (linkedIn !== null && linkedIn.length > 50) ||
    (twitter !== null && twitter.length > 50) ||
    (instagram !== null && instagram.length > 50) ||
    (bio !== null && bio.length > 2000) ||
    firstName === null ||
    firstName === ''

  return (
    <div className="EditProfile">
      <div className="EditProfile__container">
        <div className="Mb-6">
          <h2 className="Mb-2">Edit Profile</h2>
          <p className="Secondary-text">Only your Pie Friends and people you’re in plans with can see your profile.</p>
        </div>
        <EditPortrait picPreview={picPreview} error={error} isLoading={isLoading} onChange={onChange} />
        <form>
          <div className="Pb-6">
            <h3 className="Mb-6">Info</h3>
            <div className="Flex Mb-6 EditProfile__container__info">
              <Input
                className="EditProfile__container__info__first-name"
                placeHolder="First name"
                value={firstName ?? ''}
                onChange={(e) => setFirstName(e.target.value)}
                error={firstName === null || firstName === ''}
                errorText="First name is required."
              />
              <Input
                className="EditProfile__container__info__last-name"
                placeHolder="Last Name"
                value={lastName ?? ''}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <TextArea
              placeHolder="Bio"
              value={bio ?? ''}
              onChange={(e) => setBio(e.target.value)}
              autoFocus={infoType === 'bio'}
              error={bio !== null && bio.length > 2000}
              errorText="Bio must be 2000 characters or less."
            />
          </div>
          <div className="EditProfile__container__city Py-6">
            <EditCity city={city} setCity={setCity} infoType={infoType} isEditProfile />
          </div>
          <div className="EditProfile__container__interests Pt-6 Pb-6" ref={interestsRef}>
            <h3 className="Mb-1">What are you into?</h3>
            <p className="Mb-6 Caption">We’ll use this to make recommendations.</p>
            {!interestsLoading && !interestsError && (
              <InterestsPills interests={interests} setInterestsSelected={setInterestsSelected} />
            )}
          </div>
          <div className="EditProfile__container__favorite-spots Pt-6">
            <h3 className="Mb-1">What are your favorite local spots?</h3>
            <p className="Mb-6 Caption">We’ll use this to make recommendations.</p>
            <EditFavoriteSpots favoriteSpots={favoriteSpots} setFavoriteSpots={setFavoriteSpots} infoType={infoType} />
          </div>
          <div className="EditProfile__container__socials Pt-6">
            <h3 className="Mb-6">Your Socials</h3>
            <Input
              className="EditProfile__container__socials__instagram Mb-6"
              placeHolder="Instagram username"
              value={instagram ?? ''}
              onChange={(e) => setInstagram(e.target.value)}
              autoFocus={infoType === 'instagram'}
              error={instagram !== null && instagram.length > 50}
              errorText="Instagram username must be 50 characters or less."
            />
            <Input
              className="EditProfile__container__socials__twitter Mb-6"
              placeHolder="Twitter username"
              value={twitter ?? ''}
              onChange={(e) => setTwitter(e.target.value)}
              autoFocus={infoType === 'twitter'}
              error={twitter !== null && twitter.length > 50}
              errorText="Twitter username must be 50 characters or less."
            />
            <Input
              className="EditProfile__container__socials__linkedin"
              placeHolder="LinkedIn"
              value={linkedIn ?? ''}
              onChange={(e) => setLinkedIn(e.target.value)}
              autoFocus={infoType === 'linkedIn'}
              error={linkedIn !== null && linkedIn.length > 50}
              errorText="LinkedIn must be 50 characters or less."
            />
          </div>
        </form>
        <DeleteButton />
      </div>
      <div className="EditProfile__sticky-buttons">
        <ConfirmationButtons
          saveButtonText="Save"
          isDisabled={isDisabled}
          onCancelClick={handleCancel}
          onContinueClick={handleSaveInfo}
        />
      </div>
      {showModal && (
        <EditModal
          onClose={handleCloseModal}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveInfo={handleSaveInfo}
        />
      )}
    </div>
  )
}
