import { useState } from 'react'
import './Channel.scss'
import { DeclinePlanInput, Plan, RsvpType, User } from '../../__generated__/graphql'
import { ChannelMembers } from './ChannelMembers/ChannelMembers'
import { useSelector } from 'react-redux'
import Modal from '../Modal/Modal'
import { ConfirmationWithAuth } from '../ConfirmationWithAuth/ConfirmationWithAuth'
import BottomDrawer from '../BottomDrawer/BottomDrawer'
import { useMutation } from '@apollo/client'
import { ChannelPinnedMessages } from './ChannelPinnedMessages/ChannelPinnedMessages'
import { ChannelRsvpButtons } from './ChannelRsvpButtons/ChannelRsvpButtons'
import { TermsUpdate } from '../TermsUpdate/TermsUpdate'
// import { useMarkInvited } from '../../hooks/useMarkInvited'
import { ChannelPolls } from './ChannelPolls/ChannelPolls'
import { GetAppBottomDrawer } from '../GetAppBottomDrawer/GetAppBottomDrawer'
import { ChannelChatButton } from './ChannelChatButton/ChannelChatButton'
import { ChannelNavButtons } from '../ChannelNavButtons/ChannelNavButtons'
import { ChannelHeader } from './ChannelHeader/ChannelHeader'
import { ChannelInviteFriendsButton } from './ChannelInviteFriendsButton/ChannelInviteFriendsButton'
import { getPlan } from '../../graphql/queries/plans-plan'
import { planUsersDecline } from '../../graphql/mutations/plan-users-decline'
import { planUsersFindPlanMembers } from '../../graphql/queries/plan-users-find-plan-members'
import { ChannelRecentMessages } from './ChannelRecentMessages/ChannelRecentMessages'
import { PlanInfoBanner } from './PlanInfoBanner/PlanInfoBanner'
import { PlanInviteOnlyBanner } from './PlanInviteOnlyBanner/PlanInviteOnlyBanner'
import { planUsersRequestToJoin } from '../../graphql/mutations/plan-users-request-to-join'
import { ModalOrDrawer } from '../ModalOrDrawer/ModalOrDrawer'
import { ChannelInviteFriendsCallout } from './ChannelInviteFriendsCallout/ChannelInviteFriendsCallout'
import { showTerms } from '../../constants/constants'

export interface PlanDetailProps {
  plan: Plan
  handleOpenChat: () => void
}

export default function PlanDetail({ plan, handleOpenChat }: PlanDetailProps): JSX.Element {
  const isMobile = useSelector((state: any) => state.isMobile)
  const user = useSelector((state: any) => state.user as User)

  const [signUpAndRsvpModalIsOpen, setSignUpAndRsvpModalIsOpen] = useState(false)
  const [rsvpType, setRsvpType] = useState<RsvpType | null>(plan.currentUserRsvp || null)
  const [dynamicLinkUrl, setDynamicLinkUrl] = useState<string | null>(null)
  const [leavePlanModalIsOpen, setLeavePlanModalIsOpen] = useState<boolean>(false)

  const planId = plan.id
  // useMarkInvited({ planId })

  const [planUsersDeclineMutation] = useMutation<{ declinePlanInput: DeclinePlanInput }>(planUsersDecline, {
    variables: { planId },
    refetchQueries: [getPlan, planUsersFindPlanMembers],
  })

  const [planUsersRequestToJoinMutation] = useMutation(planUsersRequestToJoin, {
    variables: { planId },
    refetchQueries: [getPlan],
  })

  const didRsvpYesOrMaybe =
    plan.currentUserRsvp != null && [RsvpType.Yes, RsvpType.Maybe, RsvpType.Accepted].includes(plan.currentUserRsvp)

  const isPlanOwner = plan.ownerUserId === user.id

  const handleRsvpClick = (rsvp: RsvpType) => {
    if (plan.currentUserRsvp && plan.currentUserRsvp !== rsvp) {
      switch (rsvp) {
        case RsvpType.No:
          planUsersDeclineMutation()
            .then(() => {
              setRsvpType(RsvpType.No)
              setSignUpAndRsvpModalIsOpen(true)
            })
            .catch((err) => {
              console.error('Error declining plan!', { err })
            })
          break
        case RsvpType.Pending:
          if (user) {
            planUsersRequestToJoinMutation()
              .then(() => {
                setRsvpType(RsvpType.Pending)
                setSignUpAndRsvpModalIsOpen(true)
              })
              .catch((err) => {
                console.warn('Unexpected error in planUsers.requestToJoin mutation', { err, planId: plan.id })
              })
          }
          break
        default:
          setRsvpType(rsvp)
          setSignUpAndRsvpModalIsOpen(true)
          break
      }
    } else if ([RsvpType.Yes, RsvpType.Maybe, RsvpType.Pending].includes(rsvp)) {
      setRsvpType(rsvp)
      setSignUpAndRsvpModalIsOpen(true)
    }
  }

  const closeModal = () => {
    setSignUpAndRsvpModalIsOpen(false)
  }

  const renderDialog = () => {
    if (signUpAndRsvpModalIsOpen && rsvpType) {
      return isMobile ? (
        <BottomDrawer onHide={closeModal} enhanced showCancelButton shouldHideCloseButton>
          <ConfirmationWithAuth plan={plan} rsvpType={rsvpType} onClose={closeModal} />
        </BottomDrawer>
      ) : (
        <Modal onClose={closeModal} enhanced>
          <ConfirmationWithAuth plan={plan} rsvpType={rsvpType} onClose={closeModal} />
        </Modal>
      )
    }
    return null
  }

  const renderRequestToJoinBanner = () => {
    if (plan.enableRequestToJoin && plan.currentUserRsvp === RsvpType.Accepted)
      return (
        <div className="Flex Flex--col Flex--align-center G-3 My-3">
          <h3>You're In!</h3>
          <button className="Button-secondary Button-medium" onClick={() => setLeavePlanModalIsOpen(true)}>
            Leave Plan
          </button>
        </div>
      )
    if (plan.enableRequestToJoin && plan.currentUserRsvp === RsvpType.Pending) {
      return (
        <div className="Flex Flex--col Flex--align-center G-3 My-3">
          <i className="Large-text bi bi-inbox"></i>
          <div>
            <h3>Request to join sent!</h3>
            <p className="Caption">You will get a response soon.</p>
          </div>
          <button
            className="Button-secondary Button-medium"
            onClick={() => planUsersDeclineMutation().then(() => setRsvpType(RsvpType.No))}
          >
            Cancel Request
          </button>
        </div>
      )
    }
    return null
  }

  // Display terms update modal if required
  const termsUpdateModalRequired = user?.tosAgreedVersion !== 1
  if (showTerms && termsUpdateModalRequired && user?.id) {
    return <TermsUpdate />
  }

  const planIsAtCapacity = plan.capacity && plan.capacity.atCapacity

  // fully gated state
  if (planIsAtCapacity && !didRsvpYesOrMaybe) {
    return (
      <>
        <div className="Channel Card Flex Flex--col Flex--fill">
          {user?.id && <ChannelNavButtons plan={plan} dynamicLinkUrl={dynamicLinkUrl} />}
          <div className="Channel__content Flex--fill">
            <ChannelHeader plan={plan} setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} />
            {planIsAtCapacity && <PlanInfoBanner plan={plan} />}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="Channel Card Flex Flex--col Flex--fill">
        {user?.id && <ChannelNavButtons plan={plan} dynamicLinkUrl={dynamicLinkUrl} />}
        <div className="Channel__content Flex--fill">
          <ChannelHeader plan={plan} setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} />
          {planIsAtCapacity && <PlanInfoBanner plan={plan} />}
          {!planIsAtCapacity && !plan.guestCanInvite && plan.ownerUserId !== user.id && didRsvpYesOrMaybe && (
            <PlanInviteOnlyBanner plan={plan} />
          )}
          {!planIsAtCapacity && (plan.guestCanInvite || plan.ownerUserId === user.id) && (
            <ChannelInviteFriendsCallout plan={plan} setDynamicLinkUrl={setDynamicLinkUrl} />
          )}
          {didRsvpYesOrMaybe && <ChannelPolls plan={plan} setSignUpAndRsvpModalIsOpen={setSignUpAndRsvpModalIsOpen} />}
          <ChannelPinnedMessages pinnedMessages={plan.channel?.pinnedMessages || []} />
          <hr />
          <ChannelMembers plan={plan} />
          {didRsvpYesOrMaybe && (
            <ChannelRecentMessages messages={plan.channel?.recentMessages || []} handleOpenChat={handleOpenChat} />
          )}
        </div>

        <div className="Bar Bar-bottom">
          {renderRequestToJoinBanner()}

          {isPlanOwner && (
            <div className="Flex Flex--align-center Fill-width Flex--centered G-3">
              {plan.ownerUserId === user.id && dynamicLinkUrl && (
                <ChannelInviteFriendsButton plan={plan} dynamicLinkUrl={dynamicLinkUrl} />
              )}
              {<ChannelChatButton handleOpenChat={handleOpenChat} plan={plan} />}
            </div>
          )}

          {!isPlanOwner && <ChannelRsvpButtons plan={plan} onClick={handleRsvpClick} />}

          {renderDialog()}
        </div>
      </div>

      {/* FIXME: Removing GetAppBottomDrawer breaks all bottom drawers! */}
      <GetAppBottomDrawer />

      {leavePlanModalIsOpen && (
        <ModalOrDrawer
          setIsOpen={setLeavePlanModalIsOpen}
          content={
            <div className="Flex Flex--col Flex--align-center G-3">
              <h2 className="Text-centered">Please confirm you want to leave this plan</h2>
              <p className="Gray-text Text-centered">
                If you leave this plan then change your mind you will have to request to join the plan again.
              </p>
              <button
                className="Button-primary Button-large Mt-8 Fill-width"
                onClick={() => {
                  handleRsvpClick(RsvpType.No)
                  setLeavePlanModalIsOpen(false)
                }}
              >
                Leave Plan
              </button>
              <button
                className="Button-secondary Button-large Fill-width"
                onClick={() => setLeavePlanModalIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          }
          height="auto"
        />
      )}
    </>
  )
}
