import './MorePlans.scss'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { Plan, PlanCategory, PlansQueries } from '../../__generated__/graphql'
import { plansFind } from '../../graphql/queries/plans-find'
import { PlanCard } from '../PlanCard/PlanCard'
import { feedInvitedPathname } from '../../constants/path-names'
import { useNavigate } from 'react-router-dom'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { PlanAttribution } from '../PlanAttribution/PlanAttribution'

export const MorePlans = ({ plan }: { plan: Plan }) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })

  const navigate = useNavigate()
  const navigateToInvites = () => {
    navigate(feedInvitedPathname)
  }

  const { loading, error, data } = useQuery<{ plans: PlansQueries }>(plansFind, {
    variables: {
      category: PlanCategory.MoreInvites,
      excludedPlanIds: [plan.id],
      limit: 6,
      offset: 0,
    },
  })

  if (loading || error || !data) {
    return null
  }

  if (!data.plans.find.items.length) {
    createMixpanelEvent(MixpanelEvent.morePlansForYouLoaded, {
      plans_count: data.plans.find.items.length,
      path: location.pathname,
    })
    return null
  }

  const plansTotal = data.plans.find.total
  const plans = data.plans.find.items.slice(0, 5)

  createMixpanelEvent(MixpanelEvent.morePlansForYouLoaded, {
    plans_count: plansTotal,
    path: location.pathname,
  })

  return (
    <>
      {isMobile && <div className="Mobile-divider"></div>}
      <div className="MorePlans Card">
        <div className="MorePlans__content-container Flex Flex--col Flex--align-stretch">
          <h1 className="Mb-6">More Invites</h1>
          {plans.map((plan: Plan) => (
            <div key={plan.id}>
              <PlanAttribution friends={plan.friendsInPlan.items} wasSeen={plan.wasSeen} />
              <PlanCard key={plan.id} plan={plan} isMorePlansSection={true} />
            </div>
          ))}
          {plansTotal > 5 && (
            <button onClick={navigateToInvites} className="MorePlans__see-more-btn Button-primary Button-large">
              See All
            </button>
          )}
        </div>
      </div>
    </>
  )
}
