/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  DateOnly: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddChannelMembersInput = {
  channelId: Scalars['String'];
  isModerator?: InputMaybe<Scalars['Boolean']>;
  userIds: Array<Scalars['String']>;
};

export type AddFriendInput = {
  userId: Scalars['String'];
};

export type AddPlanUsersInput = {
  /** Only used to track a user who has invited another user. History by plan is not stored. */
  inviterPlanUserId?: InputMaybe<Scalars['String']>;
  /** plan id for the plan to rsvp with */
  planId: Scalars['String'];
  /** rsvp selection */
  rsvp?: InputMaybe<RsvpType>;
};

export type AdminMutations = {
  __typename?: 'AdminMutations';
  removeUser: Scalars['Boolean'];
  togglePublicPlan: Plan;
};


export type AdminMutationsRemoveUserArgs = {
  id: Scalars['String'];
};


export type AdminMutationsTogglePublicPlanArgs = {
  input: TogglePublicPlanInput;
};

export type BlastPlanUsersInput = {
  message: Scalars['String'];
  planId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type BulkCreateInviteDmsInput = {
  allFriends?: InputMaybe<Scalars['Boolean']>;
  excludeUserIds?: InputMaybe<Array<Scalars['String']>>;
  messageText?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type CastDateVoteInput = {
  dateText: Scalars['String'];
};

export type CastVoteInput = {
  pollOptionId: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  cid: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<ChannelDate>>;
  description?: Maybe<Scalars['String']>;
  dynamicLinkUrl?: Maybe<Scalars['String']>;
  dynamicLinkUrlWeb?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isVisibilityOpen?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Array<ChannelLink>>;
  members: Array<ChannelMember>;
  name?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['String']>;
  pinnedMessages: Array<PinnedMessage>;
  places?: Maybe<Array<ChannelPlace>>;
  pollsActive?: Maybe<Scalars['Boolean']>;
  recentMessages: Array<Message>;
  startTime?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ChannelDate = {
  __typename?: 'ChannelDate';
  dateText: Scalars['String'];
  pollDate: Scalars['DateTime'];
  voteUserIds: Array<Scalars['String']>;
};

export type ChannelLink = {
  __typename?: 'ChannelLink';
  description?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChannelMembersMutations = {
  __typename?: 'ChannelMembersMutations';
  add: Channel;
  demote: Channel;
  invited: Channel;
  promote: Channel;
  remove: Channel;
  removeMe: Channel;
};


export type ChannelMembersMutationsAddArgs = {
  input: AddChannelMembersInput;
};


export type ChannelMembersMutationsDemoteArgs = {
  input: DemoteChannelMembersInput;
};


export type ChannelMembersMutationsInvitedArgs = {
  input: InvitedChannelMemberInput;
};


export type ChannelMembersMutationsPromoteArgs = {
  input: PromoteChannelMembersInput;
};


export type ChannelMembersMutationsRemoveArgs = {
  input: RemoveChannelMembersInput;
};


export type ChannelMembersMutationsRemoveMeArgs = {
  input: RemoveMeFromChannelInput;
};

export type ChannelMembersQueries = {
  __typename?: 'ChannelMembersQueries';
  find: Array<ChannelMember>;
};


export type ChannelMembersQueriesFindArgs = {
  channelId: Scalars['String'];
};

export type ChannelPlace = {
  __typename?: 'ChannelPlace';
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesObject>;
  placeId?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
};

export enum ChannelType {
  Messaging = 'messaging',
  Planning = 'planning'
}

export type ChannelsMutations = {
  __typename?: 'ChannelsMutations';
  bulkCreateInviteDms: Scalars['Boolean'];
  castDateVote: Channel;
  create: Channel;
  createDm: Channel;
  delete: Channel;
  update: Channel;
};


export type ChannelsMutationsBulkCreateInviteDmsArgs = {
  input: BulkCreateInviteDmsInput;
};


export type ChannelsMutationsCastDateVoteArgs = {
  id: Scalars['String'];
  input: CastDateVoteInput;
};


export type ChannelsMutationsCreateArgs = {
  input: CreateChannelInput;
};


export type ChannelsMutationsCreateDmArgs = {
  otherUserId: Scalars['String'];
};


export type ChannelsMutationsDeleteArgs = {
  id: Scalars['String'];
};


export type ChannelsMutationsUpdateArgs = {
  id: Scalars['String'];
  input: UpdateChannelInput;
};

export type ChannelsPaginated = NoTotalPaginatedGql & {
  __typename?: 'ChannelsPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<Channel>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ChannelsQueries = {
  __typename?: 'ChannelsQueries';
  channel: Channel;
  findShared: ChannelsPaginated;
};


export type ChannelsQueriesChannelArgs = {
  id: Scalars['String'];
};


export type ChannelsQueriesFindSharedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  otherUserId: Scalars['String'];
  type: ChannelType;
};

export type City = {
  __typename?: 'City';
  coordinates: CoordinatesObject;
  country: Scalars['String'];
  name: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type Connection = {
  __typename?: 'Connection';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  otherUser: User;
  otherUserId: Scalars['String'];
  status: ConnectionStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export enum ConnectionStatus {
  Accepted = 'accepted',
  BlockReceived = 'blockReceived',
  Blocked = 'blocked',
  Dismissed = 'dismissed',
  RequestReceived = 'requestReceived',
  Requested = 'requested'
}

export type ConnectionsMutations = {
  __typename?: 'ConnectionsMutations';
  accept: Connection;
  block: Connection;
  dismiss: Connection;
  remove?: Maybe<User>;
  request: Connection;
  unblock?: Maybe<User>;
};


export type ConnectionsMutationsAcceptArgs = {
  otherUserId: Scalars['String'];
};


export type ConnectionsMutationsBlockArgs = {
  otherUserId: Scalars['String'];
};


export type ConnectionsMutationsDismissArgs = {
  otherUserId: Scalars['String'];
};


export type ConnectionsMutationsRemoveArgs = {
  otherUserId: Scalars['String'];
};


export type ConnectionsMutationsRequestArgs = {
  otherUserId: Scalars['String'];
};


export type ConnectionsMutationsUnblockArgs = {
  otherUserId: Scalars['String'];
};

export type ConnectionsPaginated = PaginatedGql & {
  __typename?: 'ConnectionsPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<Connection>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type ConnectionsQueries = {
  __typename?: 'ConnectionsQueries';
  find: ConnectionsPaginated;
};


export type ConnectionsQueriesFindArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ConnectionStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateChannelInput = {
  description?: InputMaybe<Scalars['String']>;
  emoji?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ChannelType>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFavoriteSpotInput = {
  address?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<FavoriteSpotCategory>;
  coordinates?: InputMaybe<CoordinatesInput>;
  generalLocation?: InputMaybe<Scalars['String']>;
  placeId: Scalars['String'];
  venue: Scalars['String'];
};

export type CreateGeneratedImageInput = {
  prompt: Scalars['String'];
};

export type CreatePlanInput = {
  /** if more than 1 entry then a date poll is active */
  dates?: InputMaybe<Array<PlanDateInput>>;
  /** user description of the plan */
  description?: InputMaybe<Scalars['String']>;
  /** If true allows accept/decline functionality. Plan cannot be updated to false once set to true. */
  enableRequestToJoin?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated */
  endTimeText?: InputMaybe<Scalars['String']>;
  /** If true, guest of the plan can invite others through the app */
  guestCanInvite?: InputMaybe<Scalars['Boolean']>;
  /** image url for the plan */
  imageUrl?: InputMaybe<Scalars['String']>;
  /** If true pie will expose users plan to more potential invitees */
  isSnowball?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated: use isSnowball */
  isVisibilityOpen?: InputMaybe<Scalars['Boolean']>;
  /** notables url links added by owner */
  links?: InputMaybe<Array<PlanLinkInput>>;
  /** the max number of attending rsvps for the plan */
  maxCapacity?: InputMaybe<Scalars['Int']>;
  /** notable plan location added by owner */
  place?: InputMaybe<PlanPlaceInput>;
  /** @deprecated: use plan.place */
  places?: InputMaybe<Array<PlanPlaceInput>>;
  /** the date the plan will be starting */
  startDateOnly?: InputMaybe<Scalars['DateTime']>;
  /** the time the plan will be starting */
  startTimeOnly?: InputMaybe<Scalars['DateTime']>;
  /** @deprecated */
  startTimeText?: InputMaybe<Scalars['String']>;
  /** selected timezone of the plan as an IANA TZ identifier */
  timezone: Scalars['String'];
  /** title of the plan */
  title: Scalars['String'];
};

export type CreatePollInput = {
  options: Array<PollOptionInput>;
  planId: Scalars['String'];
  title: Scalars['String'];
};

export type CreateReportInput = {
  message?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type DeclinePlanInput = {
  /** plan id for the plan to decline */
  planId: Scalars['String'];
};

export type DemoteChannelMembersInput = {
  channelId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export enum Device {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export enum DevicePlatform {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export type DeviceToken = {
  __typename?: 'DeviceToken';
  createdAt: Scalars['DateTime'];
  expiredAt: Scalars['DateTime'];
  id: Scalars['String'];
  platform: DevicePlatform;
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type DeviceTokensMutations = {
  __typename?: 'DeviceTokensMutations';
  delete: IdOnlyObject;
  upsert: DeviceToken;
};


export type DeviceTokensMutationsDeleteArgs = {
  token: Scalars['String'];
};


export type DeviceTokensMutationsUpsertArgs = {
  input: UpsertDeviceTokenInput;
};

export type EndPollInput = {
  pollId: Scalars['String'];
  winnerPollOptionId?: InputMaybe<Scalars['String']>;
};

export type FavoriteSpot = {
  __typename?: 'FavoriteSpot';
  address?: Maybe<Scalars['String']>;
  category?: Maybe<FavoriteSpotCategory>;
  coordinates?: Maybe<CoordinatesObject>;
  createdAt: Scalars['DateTime'];
  emoji: Scalars['String'];
  generalLocation?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  placeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  venue: Scalars['String'];
};

export enum FavoriteSpotCategory {
  Bar = 'bar',
  Cafe = 'cafe',
  Park = 'park',
  Restaurant = 'restaurant'
}

export type FavoriteSpotsMutations = {
  __typename?: 'FavoriteSpotsMutations';
  create: FavoriteSpot;
  remove: Scalars['Boolean'];
  set: Scalars['Boolean'];
};


export type FavoriteSpotsMutationsCreateArgs = {
  input: CreateFavoriteSpotInput;
};


export type FavoriteSpotsMutationsRemoveArgs = {
  id: Scalars['String'];
};


export type FavoriteSpotsMutationsSetArgs = {
  input: SetFavoriteSpotsInput;
};

export type FetchFriendIdsInput = {
  excludeUserIds?: InputMaybe<Array<Scalars['String']>>;
  planId: Scalars['String'];
  userId: Scalars['String'];
};

export type FindPollsInput = {
  planId: Scalars['String'];
};

export type FriendIdsResponse = {
  __typename?: 'FriendIdsResponse';
  /** List of friend user IDs */
  friendIds: Array<Scalars['String']>;
};

export type GenerateShareLinksInput = {
  /** plan id for the plan to generate share links */
  channelId: Scalars['String'];
};

export type GeneratedImage = {
  __typename?: 'GeneratedImage';
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  status: GeneratedImageStatus;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export enum GeneratedImageStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending'
}

export type GeneratedImagesMutations = {
  __typename?: 'GeneratedImagesMutations';
  create: GeneratedImage;
};


export type GeneratedImagesMutationsCreateArgs = {
  input: CreateGeneratedImageInput;
};

export type GeneratedImagesQueries = {
  __typename?: 'GeneratedImagesQueries';
  generatedImage: GeneratedImage;
};


export type GeneratedImagesQueriesGeneratedImageArgs = {
  id: Scalars['String'];
};

export type GroupUser = {
  __typename?: 'GroupUser';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  inviterUser: User;
  inviterUserId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type GroupUsersPaginated = PaginatedGql & {
  __typename?: 'GroupUsersPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<GroupUser>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type GroupUsersQueries = {
  __typename?: 'GroupUsersQueries';
  find: GroupUsersPaginated;
};


export type GroupUsersQueriesFindArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type IdOnlyObject = {
  __typename?: 'IdOnlyObject';
  id: Scalars['String'];
};

export type Interest = {
  __typename?: 'Interest';
  createdAt: Scalars['DateTime'];
  didSelect: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type InterestDidSelectArgs = {
  userId: Scalars['String'];
};

export type InterestsQueries = {
  __typename?: 'InterestsQueries';
  find: Array<Interest>;
};

export type LimitedUser = {
  __typename?: 'LimitedUser';
  /** user's firstname */
  firstName?: Maybe<Scalars['String']>;
  /** user id */
  id: Scalars['String'];
  /** user's lastname */
  imageUrl?: Maybe<Scalars['String']>;
};

export type MarkInvitedInput = {
  /** plan id for the plan to mark as invited */
  planId: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  text?: Maybe<Scalars['String']>;
  user: StreamUser;
};

export type MigrationsMutations = {
  __typename?: 'MigrationsMutations';
  backfillPlansStartAt: Scalars['Boolean'];
  backfillSeenPlans: Scalars['Boolean'];
  createInvitedFeedDynamicLink: PlanUserShareLinks;
  deletePlansWithoutChannelData: Scalars['Boolean'];
  migrateChannelFieldsToPlanFields: Scalars['Boolean'];
  populatePieFriends: Scalars['Boolean'];
  replaceSeededFriends: Scalars['Boolean'];
  resetIsHiddenForRsvpNo: Scalars['Boolean'];
};


export type MigrationsMutationsDeletePlansWithoutChannelDataArgs = {
  dryMode: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  admin: AdminMutations;
  channelMembers: ChannelMembersMutations;
  channels: ChannelsMutations;
  connections: ConnectionsMutations;
  deviceTokens: DeviceTokensMutations;
  favoriteSpots: FavoriteSpotsMutations;
  generatedImages: GeneratedImagesMutations;
  migrations: MigrationsMutations;
  planUsers: PlanUsersMutations;
  plans: PlansMutations;
  polls: PollsMutations;
  reactions: ReactionsMutations;
  reports: ReportsMutations;
  sendTestNotification: Scalars['Boolean'];
  smsBlaster: SmsBlasterMutations;
  userConnections: UserConnectionsMutations;
  userContacts: UserContactsMutations;
  userInterests: UserInterestsMutations;
  users: UsersMutations;
};


export type MutationSendTestNotificationArgs = {
  input: SendTestNotificationInput;
};

export type NoTotalPaginatedGql = {
  hasMore: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PaginatedGql = {
  hasMore: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type PinnedMessage = {
  __typename?: 'PinnedMessage';
  text?: Maybe<Scalars['String']>;
  user: StreamUser;
};

export type Place = {
  __typename?: 'Place';
  address: Scalars['String'];
  coordinates: CoordinatesObject;
  generalLocation: Scalars['String'];
  placeId: Scalars['String'];
  venue: Scalars['String'];
};

export type PlacesQueries = {
  __typename?: 'PlacesQueries';
  city?: Maybe<City>;
  find: Array<Place>;
};


export type PlacesQueriesCityArgs = {
  coordinates: CoordinatesInput;
};


export type PlacesQueriesFindArgs = {
  citiesOnly?: InputMaybe<Scalars['Boolean']>;
  searchTerm: Scalars['String'];
};

export type Plan = {
  __typename?: 'Plan';
  /** @deprecated: Use plan.places instea */
  address?: Maybe<Scalars['String']>;
  /** if true, the current user can edit the plan */
  canEdit: Scalars['Boolean'];
  /** Capacity data for the plan */
  capacity?: Maybe<PlanCapacity>;
  /** @deprecated */
  channel?: Maybe<Channel>;
  /** getstream.io channel cid */
  channelId: Scalars['String'];
  /** @deprecated: Use plan.places instead */
  coordinates?: Maybe<CoordinatesObject>;
  createdAt: Scalars['DateTime'];
  /** Request users rsvp status to the plan */
  currentUserRsvp?: Maybe<RsvpType>;
  /** if more than 1 entry then a date poll is active */
  dates?: Maybe<Array<PlanDate>>;
  /** user description of the plan */
  description?: Maybe<Scalars['String']>;
  /** @deprecated: Unused, waiting for client to remove from queries */
  emoji?: Maybe<Scalars['String']>;
  /** If true allows accept/decline functionality. Plan cannot be updated to false once set to true. */
  enableRequestToJoin?: Maybe<Scalars['Boolean']>;
  /** @deprecated */
  endTimeText?: Maybe<Scalars['String']>;
  /** Get friends that are in the plan */
  friendsInPlan: UsersPaginated;
  /** If true, guest of the plan can invite others through the app */
  guestCanInvite: Scalars['Boolean'];
  id: Scalars['String'];
  /** image url for the plan */
  imageUrl?: Maybe<Scalars['String']>;
  /** If true, plan will be shown to all nearby users */
  isPublic: Scalars['Boolean'];
  /** If true pie will expose users plan to more potential invitees */
  isSnowball: Scalars['Boolean'];
  /** @deprecated: use isSnowball */
  isVisibilityOpen: Scalars['Boolean'];
  /** notables url links added by owner */
  links?: Maybe<Array<PlanLink>>;
  /** the max number of attending rsvps for the plan */
  maxCapacity?: Maybe<Scalars['Int']>;
  /** last modified datetime */
  modifiedAt: Scalars['DateTime'];
  /** pie user that created this plan */
  ownerUser: User;
  /** pie user id that created this plan */
  ownerUserId: Scalars['String'];
  /** @deprecated: notable plan location added by owner */
  place?: Maybe<PlanPlace>;
  /** @deprecated: use plan.place */
  places?: Maybe<Array<PlanPlace>>;
  /** Get members of a plan as users */
  planMembers: PlanUsersPaginated;
  /** Get plan users */
  planUsers: PlanUsersPaginated;
  /** computed datetime from startTimeOnly|startDateOnly|timezone */
  startAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated */
  startDate?: Maybe<Scalars['String']>;
  /** the date the plan will be starting */
  startDateOnly?: Maybe<Scalars['DateTime']>;
  /** the time the plan will be starting */
  startTimeOnly?: Maybe<Scalars['DateTime']>;
  /** @deprecated */
  startTimeText?: Maybe<Scalars['String']>;
  /** selected timezone of the plan as an IANA TZ identifier */
  timezone?: Maybe<Scalars['String']>;
  /** title of the plan */
  title: Scalars['String'];
  /** @deprecated: identifies if the plan is a future or past plan */
  type: PlanType;
  updatedAt: Scalars['DateTime'];
  /** @deprecated */
  venue?: Maybe<Scalars['String']>;
  /** True if the plan was seen by the current user */
  wasSeen: Scalars['Boolean'];
};


export type PlanFriendsInPlanArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PlanPlanMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PlanPlanUsersArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['String']>;
  rsvp?: InputMaybe<RsvpType>;
  userId?: InputMaybe<Scalars['String']>;
};

export type PlanCapacity = {
  __typename?: 'PlanCapacity';
  /** true if plan is at capacity or greater. Can be false if maxCapcity is not set. */
  atCapacity: Scalars['Boolean'];
  /** current number of members attending the plan */
  currentCapacity: Scalars['Int'];
  /** the max number of attending rsvps for the plan */
  maxCapacity?: Maybe<Scalars['Int']>;
};

export enum PlanCategory {
  Invited = 'invited',
  Joined = 'joined',
  MoreInvites = 'moreInvites'
}

export type PlanDate = {
  __typename?: 'PlanDate';
  /** poll date option in display text format */
  text: Scalars['String'];
  /** user ids who voted for this date option */
  voteUserIds: Array<Scalars['String']>;
};

export type PlanDateInput = {
  /** poll date option in display text format */
  text: Scalars['String'];
  /** user ids who voted for this date option */
  voteUserIds: Array<Scalars['String']>;
};

export type PlanLink = {
  __typename?: 'PlanLink';
  /** user entered description for the plan url */
  description?: Maybe<Scalars['String']>;
  /** user entered plan url */
  url: Scalars['String'];
};

export type PlanLinkInput = {
  /** user entered description for the plan url */
  description?: InputMaybe<Scalars['String']>;
  /** user entered plan url */
  url: Scalars['String'];
};

export type PlanPlace = {
  __typename?: 'PlanPlace';
  /** google maps address result */
  address?: Maybe<Scalars['String']>;
  /** latitude & longitude data */
  coordinates?: Maybe<CoordinatesObject>;
  /** google maps place id */
  placeId?: Maybe<Scalars['String']>;
  /** google maps venue result */
  venue?: Maybe<Scalars['String']>;
};

export type PlanPlaceInput = {
  /** google maps address result */
  address?: InputMaybe<Scalars['String']>;
  /** latitude & longitude data */
  coordinates?: InputMaybe<CoordinatesInput>;
  /** google maps place id */
  placeId: Scalars['String'];
  /** google maps venue result */
  venue: Scalars['String'];
};

export enum PlanType {
  Future = 'future',
  Past = 'past'
}

export type PlanUser = {
  __typename?: 'PlanUser';
  Plan: Plan;
  User: User;
  createdAt: Scalars['DateTime'];
  didBoost: Scalars['Boolean'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  /** The plan object the user is part of */
  plan: Plan;
  planId: Scalars['String'];
  rsvp?: Maybe<RsvpType>;
  updatedAt: Scalars['DateTime'];
  /** The user object who is part of the plan */
  user: User;
  userId: Scalars['String'];
};

export type PlanUserShareLinks = {
  __typename?: 'PlanUserShareLinks';
  /** firebase dynamic link URL, will not fall back to app store */
  dynamicLinkUrl: Scalars['String'];
  /** firebase dynamic link URL, will fall back to app store */
  dynamicLinkUrlWeb: Scalars['String'];
};

export type PlanUsersMutations = {
  __typename?: 'PlanUsersMutations';
  /** Used to join a plan. Upsert PlanUser with rsvp 'yes'/'maybe' and add Channel members with role 'moderator'. */
  add: Scalars['Boolean'];
  /** Used to rsvp no and hide a plan. Upserts PlanUser with rsvp 'no' and 'isHidden' true, removes channel membership, and removes votes */
  decline: Scalars['Boolean'];
  /** Generates dynamic plan sharelinks for ios and web */
  generateShareLinks?: Maybe<PlanUserShareLinks>;
  /** Marks a user as invited to a plan, If PlanUser does not exist, creates one with rsvp 'invited'. */
  markInvited: Scalars['Boolean'];
  /** Used to leave a plan or remove other members. Reverts existing PlanUsers to rsvp 'invited', removes Channel members, and removes votes.  */
  remove: Scalars['Boolean'];
  /** Used to rsvp pending when plan is enableRequestToJoin=true */
  requestToJoin: Scalars['Boolean'];
  /** Used to accept a user who requested to join a plan */
  requestToJoinAccept: Scalars['Boolean'];
  /** Used to decline a user who requested to join a plan */
  requestToJoinDecline: Scalars['Boolean'];
  /** @deprecated: Use planUsers.decline */
  setIsHidden?: Maybe<PlanUser>;
  upsert?: Maybe<PlanUser>;
};


export type PlanUsersMutationsAddArgs = {
  input: AddPlanUsersInput;
};


export type PlanUsersMutationsDeclineArgs = {
  input: DeclinePlanInput;
};


export type PlanUsersMutationsGenerateShareLinksArgs = {
  input: GenerateShareLinksInput;
};


export type PlanUsersMutationsMarkInvitedArgs = {
  input: MarkInvitedInput;
};


export type PlanUsersMutationsRemoveArgs = {
  input: RemovePlanUsersInput;
};


export type PlanUsersMutationsRequestToJoinArgs = {
  input: RequestToJoinInput;
};


export type PlanUsersMutationsRequestToJoinAcceptArgs = {
  input: RequestToJoinAcceptInput;
};


export type PlanUsersMutationsRequestToJoinDeclineArgs = {
  input: RequestToJoinDeclineInput;
};


export type PlanUsersMutationsSetIsHiddenArgs = {
  input: SetHiddenInput;
};


export type PlanUsersMutationsUpsertArgs = {
  input: UpsertPlanUserInput;
};

export type PlanUsersPaginated = PaginatedGql & {
  __typename?: 'PlanUsersPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<PlanUser>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type PlanUsersQueries = {
  __typename?: 'PlanUsersQueries';
  /** Queries plan users, does not allowing querying users that rsvp'd no */
  find: PlanUsersPaginated;
  /** Queries friends in the plan who have rsvp yes/maybe */
  findFriendsInPlan: UsersPaginated;
  /** Queries members of the plan who have rsvp yes/maybe */
  findPlanMembers: PlanUsersPaginated;
  /** @deprecated(reason: "Being replaced with Pie friends */
  planMutuals: PlanUsersPaginated;
  /** Get a plan user by id */
  planUser?: Maybe<PlanUser>;
};


export type PlanUsersQueriesFindArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['String']>;
  rsvp?: InputMaybe<RsvpType>;
  userId?: InputMaybe<Scalars['String']>;
};


export type PlanUsersQueriesFindFriendsInPlanArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId: Scalars['String'];
};


export type PlanUsersQueriesFindPlanMembersArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId: Scalars['String'];
};


export type PlanUsersQueriesPlanMutualsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PlanUsersQueriesPlanUserArgs = {
  id: Scalars['String'];
};

export type PlansMutations = {
  __typename?: 'PlansMutations';
  /** Create a plan. snowballed plans will get invites added by a background job */
  create: Plan;
  /** Delete a plan. Only owner can delete a plan */
  delete: Scalars['Boolean'];
  /** Update a plan. Only owner can update a plan */
  update: Plan;
};


export type PlansMutationsCreateArgs = {
  input: CreatePlanInput;
};


export type PlansMutationsDeleteArgs = {
  id: Scalars['String'];
};


export type PlansMutationsUpdateArgs = {
  id: Scalars['String'];
  input: UpdatePlanInput;
};

export type PlansPaginated = PaginatedGql & {
  __typename?: 'PlansPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<Plan>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type PlansQueries = {
  __typename?: 'PlansQueries';
  /** Get plans */
  find: PlansPaginated;
  /** Get plan icon image urls */
  findPlanIconImageUrls: Array<Scalars['String']>;
  /** Get plans for a user */
  findPlansForUser: PlansPaginated;
  /** Get shared plans between users */
  findShared: PlansPaginated;
  /** Get plan by id */
  plan?: Maybe<Plan>;
};


export type PlansQueriesFindArgs = {
  category?: InputMaybe<PlanCategory>;
  excludedPlanIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<PlanType>;
};


export type PlansQueriesFindPlansForUserArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  otherUserId: Scalars['String'];
};


export type PlansQueriesFindSharedArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  otherUserId: Scalars['String'];
};


export type PlansQueriesPlanArgs = {
  id: Scalars['String'];
};

export type Poll = {
  __typename?: 'Poll';
  createdAt: Scalars['DateTime'];
  creatorUser: User;
  creatorUserId: Scalars['String'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  options: Array<PollOption>;
  planId: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PollOption = {
  __typename?: 'PollOption';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isWinner: Scalars['Boolean'];
  pollId: Scalars['String'];
  position: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
  votes: Array<PollVote>;
};

export type PollOptionInput = {
  value: Scalars['String'];
};

export type PollVote = {
  __typename?: 'PollVote';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pollOptionId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type PollsMutations = {
  __typename?: 'PollsMutations';
  castVote: Scalars['Boolean'];
  create: Poll;
  delete: Scalars['Boolean'];
  end: Scalars['Boolean'];
};


export type PollsMutationsCastVoteArgs = {
  input: CastVoteInput;
};


export type PollsMutationsCreateArgs = {
  input: CreatePollInput;
};


export type PollsMutationsDeleteArgs = {
  id: Scalars['String'];
};


export type PollsMutationsEndArgs = {
  input: EndPollInput;
};

export type PollsQueries = {
  __typename?: 'PollsQueries';
  find: Array<Poll>;
  poll: Poll;
};


export type PollsQueriesFindArgs = {
  input: FindPollsInput;
};


export type PollsQueriesPollArgs = {
  id: Scalars['String'];
};

export type PromoteChannelMembersInput = {
  channelId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  channelMembers: ChannelMembersQueries;
  channels: ChannelsQueries;
  connections: ConnectionsQueries;
  fetchFriendIds: FriendIdsResponse;
  generatedImages: GeneratedImagesQueries;
  groupUsers: GroupUsersQueries;
  hello: Scalars['String'];
  interests: InterestsQueries;
  limitedUser: LimitedUser;
  places: PlacesQueries;
  planUsers: PlanUsersQueries;
  plans: PlansQueries;
  polls: PollsQueries;
  userConnections: UserConnectionsQueries;
  /** check if user already exist by phonenumber */
  userExists: Scalars['Boolean'];
  users: UsersQueries;
};


export type QueryFetchFriendIdsArgs = {
  input: FetchFriendIdsInput;
};


export type QueryLimitedUserArgs = {
  id: Scalars['String'];
};


export type QueryUserExistsArgs = {
  input: UserExistsInput;
};

export type ReactionsMutations = {
  __typename?: 'ReactionsMutations';
  sendNotification: Scalars['Boolean'];
};


export type ReactionsMutationsSendNotificationArgs = {
  input: SendReactionNotificationInput;
};

export type RemoveChannelMembersInput = {
  channelId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type RemoveFriendInput = {
  userId: Scalars['String'];
};

export type RemoveMeFromChannelInput = {
  channelId: Scalars['String'];
};

export type RemovePlanUsersInput = {
  /** plan id for the plan to remove users from */
  planId: Scalars['String'];
  /** user ids to remove from the plan */
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type RemoveSuggestionInput = {
  userId: Scalars['String'];
};

export type Report = {
  __typename?: 'Report';
  message?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ReportsMutations = {
  __typename?: 'ReportsMutations';
  create: Report;
};


export type ReportsMutationsCreateArgs = {
  input: CreateReportInput;
};

export type RequestToJoinAcceptInput = {
  /** plan id for the plan */
  planId: Scalars['String'];
  /** user id for the user to accept to the plan */
  userId: Scalars['String'];
};

export type RequestToJoinDeclineInput = {
  /** plan id for the plan */
  planId: Scalars['String'];
  /** user id for the user to decline to the plan */
  userId: Scalars['String'];
};

export type RequestToJoinInput = {
  /** plan id for the plan */
  planId: Scalars['String'];
};

export enum RsvpType {
  Accepted = 'accepted',
  Declined = 'declined',
  Invited = 'invited',
  Maybe = 'maybe',
  No = 'no',
  Pending = 'pending',
  Yes = 'yes'
}

export type SendReactionNotificationInput = {
  channelId: Scalars['String'];
  messageId: Scalars['String'];
};

export type SendTestNotificationInput = {
  deeplink?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  notificationType: Scalars['String'];
  planId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SetFavoriteSpotsInput = {
  favoriteSpots: Array<CreateFavoriteSpotInput>;
};

export type SetHiddenInput = {
  /** channel id for the plan to hide */
  channelId: Scalars['String'];
  /** hides a channel */
  isHidden: Scalars['Boolean'];
};

export type SetUserInterestsInput = {
  interestIds: Array<Scalars['String']>;
};

export type SignUpInput = {
  device?: InputMaybe<Device>;
};

export type SmsBlasterMutations = {
  __typename?: 'SmsBlasterMutations';
  blastPlanUsers: Scalars['Boolean'];
};


export type SmsBlasterMutationsBlastPlanUsersArgs = {
  input: BlastPlanUsersInput;
};

export type StreamUser = {
  __typename?: 'StreamUser';
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TogglePublicPlanInput = {
  isPublic: Scalars['Boolean'];
  planId: Scalars['String'];
};

export type UpdateChannelInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isVisibilityOpen?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMeInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateOnly']>;
  city?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<CoordinatesInput>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tosAgreedVersion?: InputMaybe<Scalars['Int']>;
  twitter?: InputMaybe<Scalars['String']>;
};

export type UpdatePlanInput = {
  dates?: InputMaybe<Array<PlanDateInput>>;
  description?: InputMaybe<Scalars['String']>;
  endTimeText?: InputMaybe<Scalars['String']>;
  guestCanInvite?: InputMaybe<Scalars['Boolean']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isSnowball?: InputMaybe<Scalars['Boolean']>;
  isVisibilityOpen?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<Array<PlanLinkInput>>;
  /** the max number of attending rsvps for the plan */
  maxCapacity?: InputMaybe<Scalars['Int']>;
  /** @deprecated: notable plan location added by owner */
  place?: InputMaybe<PlanDateInput>;
  /** @deprecated: use plan.place */
  places?: InputMaybe<Array<PlanPlaceInput>>;
  startDateOnly?: InputMaybe<Scalars['DateTime']>;
  startTimeOnly?: InputMaybe<Scalars['DateTime']>;
  startTimeText?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpsertDeviceTokenInput = {
  platform: DevicePlatform;
  token: Scalars['String'];
};

export type UpsertManyUserContactsInput = {
  /** phonenumbers in E.164 format */
  phoneNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export type UpsertPlanUserInput = {
  /** plan id for the plan to upsert user */
  planId: Scalars['String'];
  /** rsvp selection */
  rsvp: RsvpType;
};

export type User = {
  __typename?: 'User';
  /** user auth id */
  authId: Scalars['String'];
  /** user provided description */
  bio?: Maybe<Scalars['String']>;
  /** user's birthday. Birthday is not retrievable for other users */
  birthday?: Maybe<Scalars['DateOnly']>;
  /** home location city */
  city?: Maybe<Scalars['String']>;
  connection?: Maybe<Connection>;
  /** home location general coordinate */
  coordinates?: Maybe<CoordinatesObject>;
  /** home location country */
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** true is user set first and uploaded photo */
  didCompleteOnboarding: Scalars['Boolean'];
  /** user's favorite spots */
  favoriteSpots: Array<FavoriteSpot>;
  /** user's firstname */
  firstName?: Maybe<Scalars['String']>;
  /** user's firstname and lastname */
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** user's uploaded profile picture */
  imageUrl?: Maybe<Scalars['String']>;
  /** instagram handle */
  instagram?: Maybe<Scalars['String']>;
  /** true if the user is a contact */
  isContact: Scalars['Boolean'];
  /** user's lastname */
  lastName?: Maybe<Scalars['String']>;
  /** linkedIn handle */
  linkedIn?: Maybe<Scalars['String']>;
  mutualConnections: UsersPaginated;
  /** home location neighborhood */
  neighborhood?: Maybe<Scalars['String']>;
  /** user's phonenumber in E.164 format. Phonenumber is not retrievable for other users */
  phoneNumber?: Maybe<Scalars['String']>;
  planUsers: PlanUsersPaginated;
  /** home location state */
  state?: Maybe<Scalars['String']>;
  suggestionContext?: Maybe<Scalars['String']>;
  /** value is greater than 0, if user agreed to terms of service */
  tosAgreedVersion?: Maybe<Scalars['Int']>;
  /** twitter handle */
  twitter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userConnection?: Maybe<UserConnection>;
  /** connection status between users */
  userConnectionStatus?: Maybe<UserConnectionStatus>;
  /** user's selected user interest */
  userInterests: Array<UserInterest>;
};


export type UserMutualConnectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserPlanUsersArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId?: InputMaybe<Scalars['String']>;
  rsvp?: InputMaybe<RsvpType>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** get connectionUser object */
  connectionUser: User;
  /** user id of the connecting user */
  connectionUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** type of user connection */
  type: UserConnectionType;
  updatedAt: Scalars['DateTime'];
  /** user id of the owner of this connection */
  userId: Scalars['String'];
};

export enum UserConnectionStatus {
  AddedMe = 'addedMe',
  AddedThem = 'addedThem',
  BothAdded = 'bothAdded'
}

export enum UserConnectionType {
  Excluded = 'excluded',
  Friend = 'friend'
}

export type UserConnectionsMutations = {
  __typename?: 'UserConnectionsMutations';
  /** Create a user connection of type friend */
  addFriend: Scalars['Boolean'];
  /** Remove a user connection of type friend */
  removeFriend: Scalars['Boolean'];
  /** Upserts a user connection of type excluded. This will prevent connected user from suggestions. */
  removeSuggestion: Scalars['Boolean'];
};


export type UserConnectionsMutationsAddFriendArgs = {
  input: AddFriendInput;
};


export type UserConnectionsMutationsRemoveFriendArgs = {
  input: RemoveFriendInput;
};


export type UserConnectionsMutationsRemoveSuggestionArgs = {
  input: RemoveSuggestionInput;
};

export type UserConnectionsPaginated = PaginatedGql & {
  __typename?: 'UserConnectionsPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<UserConnection>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserConnectionsQueries = {
  __typename?: 'UserConnectionsQueries';
  /** Find friends of user */
  findFriends: UserConnectionsPaginated;
  /** Find mutual friends for current user and provided user ids */
  findMutualFriends: UsersPaginated;
};


export type UserConnectionsQueriesFindFriendsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserConnectionsQueriesFindMutualFriendsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  otherUserId: Scalars['String'];
};

export type UserContactsMutations = {
  __typename?: 'UserContactsMutations';
  deleteAll: Scalars['Boolean'];
  /** Upsert many contacts for users. Contacts can be used in friend suggestions and invites */
  upsertMany: Scalars['Boolean'];
};


export type UserContactsMutationsUpsertManyArgs = {
  input: UpsertManyUserContactsInput;
};

export type UserExistsInput = {
  /** phonenumber in E.164 format */
  phoneNumber: Scalars['String'];
};

export type UserInterest = {
  __typename?: 'UserInterest';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  interest: Interest;
  interestId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type UserInterestsMutations = {
  __typename?: 'UserInterestsMutations';
  set: Scalars['Boolean'];
};


export type UserInterestsMutationsSetArgs = {
  input: SetUserInterestsInput;
};

export type UsersMutations = {
  __typename?: 'UsersMutations';
  /** delete the current user */
  deleteMe: IdOnlyObject;
  /** initializes the user from jwt */
  signUp: User;
  /** update the current user */
  updateMe: User;
};


export type UsersMutationsSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};


export type UsersMutationsUpdateMeArgs = {
  input: UpdateMeInput;
};

export type UsersPaginated = PaginatedGql & {
  __typename?: 'UsersPaginated';
  hasMore: Scalars['Boolean'];
  items: Array<User>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type UsersQueries = {
  __typename?: 'UsersQueries';
  /** snowball user suggestions */
  boostedUsers: Array<User>;
  /** find users */
  find: UsersPaginated;
  /** find friend suggestions from contact sync */
  findContactFriendSuggestions: UsersPaginated;
  /** find friend suggestions from past plans and contact list */
  findFriendSuggestions: UsersPaginated;
  /** find user suggestions from past plans and connections */
  findUserSuggestions: UsersPaginated;
  /** get the current user */
  me: User;
  /** search users by name */
  searchByName: UsersPaginated;
  /** search contacts to invite to a plan */
  searchInviteByName: UsersPaginated;
  /** get a user by id */
  user: User;
};


export type UsersQueriesFindArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UsersQueriesFindContactFriendSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UsersQueriesFindFriendSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UsersQueriesFindUserSuggestionsArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UsersQueriesSearchByNameArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
};


export type UsersQueriesSearchInviteByNameArgs = {
  excludedUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  planId: Scalars['String'];
  searchTerm: Scalars['String'];
};


export type UsersQueriesUserArgs = {
  id: Scalars['String'];
};

export type InvitedChannelMemberInput = {
  channelId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  inviterPlanUserId?: InputMaybe<Scalars['String']>;
  isModerator?: InputMaybe<Scalars['Boolean']>;
  rsvp?: InputMaybe<RsvpType>;
};
