import mixpanel from 'mixpanel-browser'

export function initMixpanel() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
    // debug: true, // enable for debugging
    // ignore_dnt: true,
    track_pageview: true,
    persistence: 'localStorage',
  })
}
