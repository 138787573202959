import './DeleteUserModal.scss'
import { useMemo, useState } from 'react'
import { ModalOrDrawer } from '../ModalOrDrawer/ModalOrDrawer'

import { feedJoinedPathname } from '../../constants/path-names'
import { DeleteCurrentUser } from '../../graphql/mutations/user-delete-current'
import { useMutation } from '@apollo/client'
import { getAuth } from '@firebase/auth'
import { store } from '../../store/store'
import { useNavigate } from 'react-router-dom'
// import mixpanel from 'mixpanel-browser'

interface DeleteUserModalProps {
  show?: boolean
  onClose: (value: boolean) => void
}

export function DeleteUserModal(props: DeleteUserModalProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const [DeleteCurrentUserMutation] = useMutation(DeleteCurrentUser, {})

  const handleUserAccountDelection = async () => {
    setLoading(true)
    await DeleteCurrentUserMutation()
    await getAuth()
      .signOut()
      .catch((e) => console.log(e))
    setLoading(false)

    store.dispatch({ type: 'user/logout' })
    navigate(feedJoinedPathname)
    location.reload()
  }

  const content = useMemo(
    () => (
      <div className="Container">
        <div className="Flex Flex--col G-3 Flex--align-center">
          <h2>Delete your account?</h2>
          <p className="Mb-8">{`Your account will be permanently deleted.`}</p>
          <button
            className="Button-primary Button-large Fill-width deleteBttn"
            disabled={loading}
            onClick={handleUserAccountDelection}
          >
            Delete Account
          </button>
          <button className="Button-secondary Button-large Fill-width" onClick={() => props.onClose(false)}>
            Cancel
          </button>
        </div>
      </div>
    ),

    [],
  )

  if (!props.show) {
    return null
  }

  return <ModalOrDrawer setIsOpen={props.onClose} content={content} height={'auto'} />
}
