import PieLogoWordmark from '../../assets/svg/PieLogoWordmark.svg'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProfileMenu } from '../ProfileMenu/ProfileMenu'
import { useSelector } from 'react-redux'
import { StreamChat, User } from 'stream-chat'
import { planIdToChannelId } from '../../util/plan-id-to-channel-id'
import './Header.scss'
import { feedInvitedPathname, signUpOrLogInPathname, getPathForPlan } from '../../constants/path-names'
import { channelIdToPlanId } from '../../util/channel-id-to-plan-id'
import { AuthType } from '../../util/auth-type.enum'
import { AppLink } from '../../common-components/AppLink/AppLink'
import Apple from '../../assets/svg/Apple.svg'
import GooglePlay from '../../assets/svg/GooglePlay.svg'
import { streamApiKey } from '../../constants/constants'
import { BREAKPOINT } from '../../constants/dimensions'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useWindowSize } from 'react-use'
import { useDeviceInfo } from '../../hooks/useDeviceInfo'

function createLabelCtaGetApp(screenWidth: number, isMobileDevice: boolean): string {
  const isMobile = screenWidth < BREAKPOINT.sm
  if (isMobile) {
    return isMobileDevice ? 'Open' : 'Download'
  }
  return isMobileDevice ? 'Open the app' : 'Get the app'
}

const Header = () => {
  const refStreamChatClient = useRef<StreamChat>(StreamChat.getInstance(`${streamApiKey}`))
  const { width: screenWidth } = useWindowSize()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const userId = useSelector((state: any) => (state.user as User)?.id)
  const chatToken = useSelector((state: any) => state.chatToken)
  const channelId = searchParams.get('id') || planIdToChannelId(useParams().id)
  const [unreadCount, setUnreadCount] = useState<number>()

  function handleLogIn() {
    if (channelId) {
      navigate(signUpOrLogInPathname, {
        state: {
          redirectTo: getPathForPlan(channelIdToPlanId(channelId)) || feedInvitedPathname,
          authType: AuthType.signIn,
        },
      })
    } else {
      navigate(signUpOrLogInPathname)
    }
  }

  useEffect(() => {
    const streamChatClient = refStreamChatClient.current
    const subscription = streamChatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        setUnreadCount(event.total_unread_count)
      }
    })
    return subscription.unsubscribe.bind(subscription)
  }, [])

  // Initial unread count must be requested via stream API, after that we use the listener above
  // https://getstream.io/chat/docs/react/unread/#fetch-unread-api
  useEffect(() => {
    const streamChatClient = refStreamChatClient.current
    if (streamChatClient && chatToken && userId) {
      axios
        .get(`https://chat.stream-io-api.com/unread?user_id=${userId}&api_key=${streamApiKey}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: chatToken,
            'stream-auth-type': 'jwt',
          },
        })
        .then((response) => {
          setUnreadCount(response.data.total_unread_count)
        })
        .catch((err) => {
          console.error('Error fetching unread count for user', err)
        })
    }
  }, [chatToken, userId])

  const isPagePlanDetail = channelId != null
  const hasButtonLogIn = isPagePlanDetail && userId == null

  const { isAndroid, isApple, isNeither } = useDeviceInfo()

  return (
    <header className="App-header">
      <a href="/">
        <img className="App-header-pie-logo-wordmark" src={PieLogoWordmark} alt="Pie" />
      </a>
      <div className="App-header-download-link-profile-container">
        {hasButtonLogIn && (
          <button className="App-header-btn Log-in-btn" onClick={handleLogIn}>
            Log in
          </button>
        )}
        {!hasButtonLogIn && (isApple || isNeither) && (
          <AppLink className="App-header-btn App-header-download-link" location="header" store="apple app store">
            <img className="App-header-download-link-image" src={Apple} alt="Apple Logo" />
            {createLabelCtaGetApp(screenWidth, isApple)}
          </AppLink>
        )}
        {!hasButtonLogIn && (isAndroid || isNeither) && (
          <AppLink className="App-header-btn App-header-download-link" location="header" store="google play store">
            <img className="App-header-download-link-image" src={GooglePlay} alt="Google Play Logo" />
            {createLabelCtaGetApp(screenWidth, isAndroid)}
          </AppLink>
        )}
        <ProfileMenu unreadCount={unreadCount || 0} />
      </div>
    </header>
  )
}

export default Header
