import { useQuery } from '@apollo/client'
import { Plan, PlanCategory, PlansQueries, PlanType } from '../../__generated__/graphql'
import { plansFind } from '../../graphql/queries/plans-find'
import Loader from '../Loader/Loader'
import { PlanCard } from '../PlanCard/PlanCard'
import './PlanList.scss'
import Error from '../Error/Error'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { useNavigate, useParams } from 'react-router-dom'
import { feedJoinedPathname, planCreateOrEditPathname } from '../../constants/path-names'
import { PlanListEmpty } from '../PlanListPage/PlanListEmpty/PlanListEmpty'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useState } from 'react'
import { PlanAttribution } from '../PlanAttribution/PlanAttribution'
import { SkeletonLoaderPlanList } from '../SkeletonLoaderPlanList/SkeletonLoaderPlanList'
import { enablePlanCreation } from '../../constants/constants'
import { PreviousView } from '../../types/previous-view.enum'

// TODO: introduce pagination mechanism
export const PlanList = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [plans, setPlans] = useState([] as Plan[])
  const [hasMore, setHasMore] = useState(false)
  const [offset, setOffset] = useState(0)

  const category = (useParams().category as PlanCategory) || PlanCategory.Invited
  const limit = 10

  const { error, fetchMore } = useQuery<{ plans: PlansQueries }>(plansFind, {
    variables: {
      category,
      limit,
      offset,
    },
    onCompleted: (data) => {
      if (offset === 0) {
        setPlans(data.plans.find.items)
        setHasMore(data.plans.find.hasMore)
        setOffset(offset + limit)
        createMixpanelEvent(MixpanelEvent.viewPlansList, {
          total_plans_count: data.plans.find.total,
          category: location.pathname === feedJoinedPathname ? PlanCategory.Joined : PlanCategory.Invited,
        })
      }
      setLoading(false)
    },
  })

  const makePlan = () => {
    createMixpanelEvent(MixpanelEvent.clickMakePlanButton)
    navigate(planCreateOrEditPathname, {
      state: {
        previousView: location.pathname === feedJoinedPathname ? PreviousView.joinedFeed : PreviousView.invitedFeed,
      },
    })
  }

  if (error) {
    console.error('Unexpected plans find response', { category, limit, offset, hasMore, plans, error })
    return <Error />
  }
  if (loading && offset === 0) {
    return (
      <div className="Card PlanList">
        <div className="PlanList__content-container">
          <SkeletonLoaderPlanList />
        </div>
      </div>
    )
  }

  if (!plans.length && !loading) {
    if (location.pathname === feedJoinedPathname) {
      return <PlanListEmpty text={'Your plans will show up here'} />
    }
    return <PlanListEmpty text={'Invites from friends will appear here'} />
  }

  const fetchMorePlans = async () => {
    setLoading(true)
    const { data } = await fetchMore({ variables: { category, limit, offset } })
    setPlans((prevPlans) => [...prevPlans, ...data.plans.find.items])
    setHasMore(data.plans.find.hasMore)
    setOffset(offset + limit)
    setLoading(false)
  }

  const futurePlans = plans?.filter((plan) => plan.type === PlanType.Future) || []
  const pastPlans = plans?.filter((plan) => plan.type === PlanType.Past) || []
  const isInviteTab = category === PlanCategory.Invited

  return (
    <div className="Card PlanList">
      <div className="PlanList__content-container">
        <InfiniteScroll dataLength={plans.length} next={fetchMorePlans} hasMore={hasMore} loader={<Loader />}>
          {plans.length > 0 && isInviteTab && <h1 className="Mb-6">You're invited!</h1>}
          {futurePlans.length > 0 && !isInviteTab && <h1 className="Mb-6">Upcoming</h1>}
          {futurePlans.map((plan: Plan) => (
            <div key={plan.id}>
              {isInviteTab && <PlanAttribution friends={plan.friendsInPlan.items} wasSeen={plan.wasSeen} />}
              <PlanCard key={plan.id} plan={plan} category={category} />
            </div>
          ))}
          {pastPlans.length > 0 && !isInviteTab && <h1 className="My-6">Past</h1>}
          {pastPlans.map((plan: Plan) => (
            <PlanCard key={plan.id} plan={plan} category={category} />
          ))}
        </InfiniteScroll>
        {enablePlanCreation && (
          <div className="Flex Flex--centered PlanList__content-container__buttons">
            <button className="Button-primary Button-large" onClick={makePlan}>
              Make a Plan
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
