export function isInAppBrowser(): boolean {
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
    const userAgent = window.navigator.userAgent
    return /instagram/i.test(userAgent) || /fbav|fbios|fb_iab|fban/i.test(userAgent)
  }
  // assume not in an in-app browser if `window` or `navigator` is unavailable
  return false
}

export function getBrowserLink() {
  const userAgent = navigator.userAgent ?? navigator.vendor ?? (window as any).opera
  let url = window.location.href
  if (/android/i.test(userAgent)) {
    // For Android devices
    const intentUrl = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`
    url = intentUrl
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    // On Apple devices, open in safari
    url = url.replace(/^https?:\/\//, 'x-safari-https://')
  }
  return url
}
