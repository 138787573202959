import { useEffect, useState } from 'react'
import SwitchBrowsers from '../../assets/svg/SwitchBrowsers.svg'
import PieLogoWordmarkWithTagline from '../../assets/svg/PieLogoWordmarkWithTagline.svg'
import './InAppBrowserView.scss'
import { getBrowserLink } from '../../util/detect-in-app-browser'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'

export const InAppBrowserView = () => {
  const [title, setTitle] = useState('')

  const handleClick = () => {
    createMixpanelEvent(MixpanelEvent.openInBrowserPressed)
  }

  useEffect(() => {
    const envTitles = process.env.REACT_APP_IN_APP_PAGE_TITLES?.replace(/\\'/g, "'") ?? '[]'
    const potentialTitles = JSON.parse(envTitles)
    const randomNumber = Math.random() * potentialTitles.length
    const randomIndex = Math.floor(randomNumber)
    const headerTitle = potentialTitles[randomIndex].replace(', ', ',\n')
    setTitle(headerTitle)
    createMixpanelEvent(MixpanelEvent.appSession, {
      host: window.location.host,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      pathname: location.pathname,
      title: headerTitle,
    })
  }, [])

  return (
    <main className="InAppBrowserView-root">
      {title && (
        <>
          <h1>{title}</h1>
          <img src={SwitchBrowsers} />
          <p>switch to your default browser to rsvp without any hassle. you're just one click away!</p>
          <a href={getBrowserLink()} target="_blank" onClick={handleClick}>
            open in browser
          </a>
          <img src={PieLogoWordmarkWithTagline} />
        </>
      )}
    </main>
  )
}
