import { MixpanelEvent } from '../types/mixpanel-event.enum'
import mixpanel from 'mixpanel-browser'

export const createMixpanelEvent = (event: MixpanelEvent, data: any = {}) => {
  // console.log('Mixpanel event', event, data)
  mixpanel.track(event, {
    ...data,
    platform: 'web',
    path: location.pathname,
  })
}
