import type { JSX } from 'react'
import type { CalendarVariantValue } from './constants'

import { CalendarEvent } from 'calendar-link'

import { getCalendarLinkProps } from './utils'
import { createMixpanelEvent } from '../../util/create-mixpanel-event'
import { MixpanelEvent } from '../../types/mixpanel-event.enum'
import { Plan, User } from '../../__generated__/graphql'

export interface CalendarLinkProps {
  type: CalendarVariantValue
  event: CalendarEvent
  id?: string
  shouldntOpenNewTab?: boolean
  userId: User['id']
  planId: Plan['id']
  planTitle: Plan['title']
}

// todo: why we need to remove rel="noopener noreferrer" for apple calendar links
export function CalendarLink({
  type,
  event,
  id,
  shouldntOpenNewTab,
  userId,
  planId,
  planTitle,
}: CalendarLinkProps): JSX.Element {
  const { href, logo, alt } = getCalendarLinkProps(type, event)

  const handleClick = () => {
    createMixpanelEvent(MixpanelEvent.addToCalendar, {
      user_id: userId,
      plan_id: planId,
      plan_title: planTitle,
      location: 'rsvp',
      service: type,
    })
  }

  return (
    <a
      className="EnhancedConfirmation__calendars__item"
      href={href}
      target={shouldntOpenNewTab ? undefined : '_blank'}
      id={id}
      onClick={handleClick}
    >
      <img src={logo} alt={alt} />
      <span>{type}</span>
    </a>
  )
}
