import './PlanSettings.scss'
import PartyPopper from '../../../assets/svg/PartyPopper.svg'
import SuperSparkle from '../../../assets/svg/SuperSparkle.svg'
import UpperLimit from '../../../assets/svg/UpperLimit.svg'
import TicketWithCheckmark from '../../../assets/svg/TicketWithCheckmark.svg'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { ConfirmationButtons } from '../../ConfirmationButtons/ConfirmationButtons'
import { Plan } from '../../../__generated__/graphql'
import { Input } from '../../Input/Input'
import { useState } from 'react'

export const PlanSettings = ({
  plan,
  guestCanInvite,
  setGuestCanInvite,
  isSnowball,
  setIsSnowball,
  enableRequestToJoin,
  setEnableRequestToJoin,
  capacityEnabled,
  setCapacityEnabled,
  maxCapacity,
  setMaxCapacity,
  savePlan,
  onCancelClick,
  confirmationButtonIsDisabled,
}: {
  plan: Plan | undefined
  guestCanInvite: boolean
  setGuestCanInvite: (guestCanInvite: boolean) => void
  isSnowball: boolean
  setIsSnowball: (isSnowball: boolean) => void
  enableRequestToJoin: boolean
  setEnableRequestToJoin: (enableRequestToJoin: boolean) => void
  capacityEnabled: boolean
  setCapacityEnabled: (capacityEnabled: boolean) => void
  maxCapacity: number | null
  setMaxCapacity: (capacity: number | null) => void
  savePlan: () => void
  onCancelClick: () => void
  confirmationButtonIsDisabled: boolean
}) => {
  const isMobile = useSelector((state: any) => {
    return state.isMobile
  })
  const [capacityErrorText, setCapacityErrorText] = useState<string>('')

  const handleCancelClick = () => {
    setGuestCanInvite(plan?.guestCanInvite || true)
    setIsSnowball(plan?.isSnowball || false)
    setEnableRequestToJoin(plan?.enableRequestToJoin || false)
    onCancelClick()
  }

  const onContinueClick = async () => {
    await savePlan()
  }

  const setCapacityEnabledAndCapacity = (e: any) => {
    setCapacityEnabled(e.target.checked)
    setMaxCapacity(maxCapacity || 10)
  }

  const setMaxCapacityAndUpdateErrorText = (e: any) => {
    const currentRsvpCount = plan?.capacity?.currentCapacity
    const value = Number(e.target.value)
    setMaxCapacity(value)
    if (currentRsvpCount != null && value < currentRsvpCount) {
      setCapacityErrorText('Capacity cannot be set lower than the current RSVP count')
    } else if (value < 2) {
      setCapacityErrorText('Capacity must be at least 2 people.')
    } else {
      setCapacityErrorText('')
    }
  }
  return (
    <>
      <div className="PlanSettings Flex Flex--col Flex--align-center G-6">
        {isMobile && <h3>{plan ? 'Plan Settings' : 'How should this plan work?'}</h3>}
        {!isMobile && <h1>{plan ? 'Plan Settings' : 'How should this plan work?'}</h1>}

        <div className={`Flex Flex--col G-6`} style={{ width: isMobile ? '100%' : '400px' }}>
          <div className="PlanSettings__option Flex Flex--align-center Flex--space-between G-6">
            <div className="Flex Flex--align-center G-6">
              <img src={PartyPopper} />
              <h5 className="PlanSettings__option__text">Guest Can Invite Friends</h5>
            </div>
            <Form.Check
              className="PlanSettings__option__toggle"
              type="switch"
              onChange={(e: any) => setGuestCanInvite(e.target.checked)}
              checked={guestCanInvite}
            />
          </div>
          <div className="PlanSettings__option Flex Flex--align-center Flex--space-between G-6">
            <div className="Flex Flex--align-center G-6">
              <img src={TicketWithCheckmark} />
              <div>
                <h5 className="PlanSettings__option__text">Accept or Decline Guests</h5>
                <p className="Caption">
                  Invitees must request to join the plan. This setting cannot be changed once the plan is made.
                </p>
              </div>
            </div>
            <Form.Check
              disabled={!!plan}
              className="PlanSettings__option__toggle"
              type="switch"
              onChange={(e: any) => setEnableRequestToJoin(e.target.checked)}
              checked={enableRequestToJoin}
            />
          </div>
          <div className="PlanSettings__option Flex Flex--align-center Flex--space-between G-6">
            <div className="Flex Flex--align-center G-6">
              <img src={UpperLimit} />
              <h5 className="PlanSettings__option__text">Set Plan Capacity</h5>
            </div>
            <Form.Check
              className="PlanSettings__option__toggle"
              type="switch"
              onChange={setCapacityEnabledAndCapacity}
              checked={capacityEnabled}
            />
          </div>
          {capacityEnabled && (
            <div className="PlanSettings__capacity">
              <div className="Flex Flex--align-center G-2">
                <Input
                  className="PlanSettings__capacity__input"
                  value={maxCapacity !== null ? `${maxCapacity}` : '10'}
                  inputType="number"
                  pattern="\d*"
                  onChange={setMaxCapacityAndUpdateErrorText}
                />
                <p className="Gray-text Flex--grow">people can join this plan.</p>
              </div>

              {capacityErrorText && <p className="Pink-text Mt-2">{capacityErrorText}</p>}
            </div>
          )}
          <div className="PlanSettings__option Flex Flex--align-center Flex--space-between G-6">
            <div className="Flex Flex--align-center G-6">
              <img src={SuperSparkle} />
              <div>
                <h5 className="PlanSettings__option__text">Snowball Mode</h5>
                <p className="Caption">Pie will help with invites.</p>
              </div>
            </div>
            <Form.Check
              className="PlanSettings__option__toggle"
              type="switch"
              onChange={(e: any) => setIsSnowball(e.target.checked)}
              checked={isSnowball}
            />
          </div>
        </div>
      </div>
      <div className="PlanSettings__sticky-container Fill-width">
        <ConfirmationButtons
          saveButtonText={plan ? 'Done' : 'Make Plan'}
          onCancelClick={handleCancelClick}
          onContinueClick={onContinueClick}
          isDisabled={confirmationButtonIsDisabled || (capacityEnabled && Boolean(capacityErrorText))}
        />
      </div>
    </>
  )
}
