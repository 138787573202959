import { useRef } from 'react'

export function useDeviceInfo() {
  const deviceInfo = useRef({
    isAndroid: /android|linux arm/i.test(navigator.userAgent),
    isApple: /iPhone|iPad|iPod/i.test(navigator.userAgent),
    isNeither: !/android|linux arm|iPhone|iPad|iPod/i.test(navigator.userAgent),
  })

  return deviceInfo.current
}
